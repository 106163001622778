export const useSelectsStore = defineStore('selectsStore', () => {
    const { execute: getCountries, data: countries } = useLazyApiFetch(
        '/api/v1/selects/countries',
        'selects:countries',
        {
            immediate: false,
            watch: false,
            dedupe: 'defer',
            transform: (data) => {
                return data?.data ?? data;
            },
        }
    );

    const { execute: getTeamTypes, data: teamTypes } = useLazyApiFetch(
        '/api/v1/selects/team-types',
        'selects:teamTypes',
        {
            immediate: false,
            watch: false,
            dedupe: 'defer',
            transform: (data) => {
                return data?.data ?? data;
            },
        }
    );

    return {
        countries,
        teamTypes,
        getCountries,
        getTeamTypes,
    };
});
